import { useCart, useCategories, useOffers, useSales } from '@api'
import { useAnalytics } from '@hooks'
import { prefetchQueries } from '@tools/query'
import {
  CategoriesList,
  CategoriesListSkeleton,
  ImageCarousel,
  ImageCarouselSkeleton,
  Loading,
  Metadata,
  NewsLine,
  ProductsCarousel,
  SalesSkeleton,
  ViewAllButton,
} from '@uikit/organisms'
import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

interface HomePageProps {
  hideMetadata?: boolean
}

const HomePage = ({ hideMetadata }: HomePageProps) => {
  const { t } = useTranslation()

  const cartQuery = useCart()
  const categoriesQuery = useCategories()
  const offersQuery = useOffers()
  const salesQuery = useSales()

  useAnalytics([offersQuery, salesQuery, categoriesQuery], [], analytics =>
    analytics.mainPageComplete(),
  )

  return (
    <>
      {!hideMetadata && (
        <Metadata
          title={t('pages.home.title')}
          description={t('pages.home.description')}
          image="/bairro-logo.png"
        />
      )}

      <NewsLine />

      <Loading
        type="query"
        query={cartQuery}
        loader="skeleton"
        Skeleton={<SalesSkeleton />}
      >
        <Loading
          type="query"
          query={salesQuery}
          loader="skeleton"
          Skeleton={<SalesSkeleton />}
        >
          {salesQuery => (
            <ProductsCarousel
              products={
                salesQuery.data?.length >= 12
                  ? salesQuery.data?.slice(0, 11)
                  : salesQuery.data
              }
              additionalComponents={[
                <div className="absolute bottom-1/2">
                  <ViewAllButton />
                </div>,
              ]}
              isSalesTitle
              title={t('pages.home.sections.sales')}
              nextButtonClassName="xs:top-[10%] -right-2.5"
              prevButtonClassName="xs:top-[10%] -left-2.5"
            />
          )}
        </Loading>
      </Loading>

      <Loading
        type="query"
        query={offersQuery}
        loader="skeleton"
        Skeleton={
          <ImageCarouselSkeleton
            titleClassName="hidden lg:block"
            className="my-5"
          />
        }
      >
        {offersQuery => (
          <ImageCarousel
            slides={offersQuery.data.map(offer => ({
              href: `/offers/${offer.id}`,
              image: offer.media.large,
            }))}
            containerClassName="my-5 lg:my-0"
          />
        )}
      </Loading>

      <Loading
        type="query"
        query={categoriesQuery}
        loader="skeleton"
        Skeleton={<CategoriesListSkeleton className="lg:mt-5" />}
      >
        {categoriesQuery => (
          <CategoriesList categories={categoriesQuery.data.categoriesList} />
        )}
      </Loading>
    </>
  )
}

export default HomePage

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  ...options
}) => {
  const dehydratedState = await prefetchQueries(options)

  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'])),
      ...dehydratedState,
    },
  }
}
